export enum EContactMethod {
  SMS = 'sms',
  EMAIL = 'email',
  PUSH = 'push',
}

export const ContactMethodTranslationKey = {
  [EContactMethod.SMS]: 'notification.sms',
  [EContactMethod.EMAIL]: 'notification.email',
  [EContactMethod.PUSH]: 'notification.push',
} as const;

import { mixed, object, string } from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { EContactMethod, ECountry } from '../interface';

const MIN_PHONE_NUMBER_LENGTH = 6;

export type CreateAlertForm<T extends object> = T & {
  notification: EContactMethod;
  phone: {
    countryIso: ECountry;
    number: string;
  };
};

export const createAlertSchema = <T extends ObjectShape>(additions: T) =>
  object().shape({
    ...additions,
    notification: mixed<EContactMethod>().oneOf(Object.values(EContactMethod)),
    phone: object()
      .shape({
        countryIso: mixed<ECountry>().oneOf(Object.values(ECountry)),
        number: string(),
      })
      .when('notification', {
        is: EContactMethod.SMS,
        then: object().shape({
          countryIso: mixed<ECountry>().oneOf(Object.values(ECountry)),
          number: string().min(MIN_PHONE_NUMBER_LENGTH).required(),
        }),
      }),
  });

import { useState, useEffect, useCallback, ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';

export type TransitionWrapperProps = {
  isOpen: boolean;
  children: ReactNode;
};

const TRANSITION_DURATION = '0.35s';

const expandingAnimation = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 200px;
    opacity: 1;
  }
`;

const collapsingAnimation = keyframes`
  from {
    max-height: 200px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ isOpen, children }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }
  }, [isOpen]);

  const handleAnimationEnd = useCallback(() => {
    if (!isOpen) {
      setIsVisible(false);
    }
  }, [isOpen]);

  return (
    <StyledWrapper isOpen={isOpen} onAnimationEnd={handleAnimationEnd}>
      {isVisible && children}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<TransitionWrapperProps>(
  ({ isOpen }) => css`
    animation: ${isOpen ? expandingAnimation : collapsingAnimation} ${TRANSITION_DURATION}
      ${isOpen ? 'ease-out' : 'ease-in'};
    opacity: ${isOpen ? 1 : 0};
    max-height: ${isOpen ? '100vh' : 0};
  `,
);

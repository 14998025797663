import Trans from 'next-translate/Trans';
import { useEffect, useMemo, useState } from 'react';
import { Path } from 'react-hook-form';

import {
  Box,
  FormControl,
  Grid,
  Image,
  LinkTranslatedStyledAsLink,
  SelectTextControl,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { getStaticPathIconFolder } from '@core/media';
import {
  ContactMethodTranslationKey,
  EColor,
  EContactMethod,
  ERouting,
  ETypographyVariant,
  SelectOption,
} from '@core/type';
import { detectMobileOS } from '@core/util/user-agent';

import { InputPhoneWithCountry } from '../phone-field';
import { TransitionWrapper } from './TransitionWrapper';
import { AlertFormProps } from './interface-alert-form';

export const AlertForm = <T, Result>({
  actions,
  children,
  formProps,
  phoneInputProps,
  gridContainerProps,
  notificationCellProps,
  phoneCellProps,
}: AlertFormProps<T, Result>) => {
  const { t: tForm } = formProps;
  const { watch, control, errors } = phoneInputProps;
  const notification = watch('notification' as Path<T>);

  const [isMobileOs, setIsMobileOs] = useState(false);

  const notificationOptions: SelectOption[] = useMemo(() => {
    return Object.values(EContactMethod).map((method) => ({
      label: tForm(ContactMethodTranslationKey[method]),
      content: tForm(ContactMethodTranslationKey[method]),
      value: method,
    }));
  }, []);

  useEffect(() => {
    setIsMobileOs(detectMobileOS(window.navigator.userAgent) !== 'unknown');
  }, []);

  return (
    <FormControl {...formProps}>
      <Grid isContainer columnSpacing={8} rowSpacing={32} {...gridContainerProps}>
        {children}
        <Grid {...notificationCellProps}>
          <SelectTextControl
            control={control}
            name="notification"
            required
            options={notificationOptions}
            label={tForm('notification.placeholder')}
            isError={!!(errors as Record<string, unknown>)?.['notification']}
          />
        </Grid>
      </Grid>

      <TransitionWrapper isOpen={notification === EContactMethod.SMS}>
        <Box marginTop="32px">
          <Grid isContainer columnSpacing={8}>
            <Grid {...phoneCellProps}>
              <Box>
                <Typography variant={ETypographyVariant.BODY3} display="inline">
                  {tForm('phoneNumber.title')}
                </Typography>
                <Typography display="inline" color={EColor.DANGER} marginLeft={'4px'}>
                  *
                </Typography>
              </Box>
              <InputPhoneWithCountry {...phoneInputProps} />
            </Grid>
          </Grid>
        </Box>
      </TransitionWrapper>
      <TransitionWrapper isOpen={notification === EContactMethod.PUSH}>
        <Box display="flex" flexDirection="column" marginTop="32px">
          <Box>
            <Trans
              i18nKey={`${Translate.common.FORM}:notification.pushText`}
              components={{
                link: isMobileOs ? (
                  <LinkTranslatedStyledAsLink
                    keyRoute={ERouting.MOBILE_APP_REDIRECTION}
                    color={EColor.ACCENT}
                  />
                ) : null,
              }}
            />
          </Box>

          {!isMobileOs && (
            <Image
              src={getStaticPathIconFolder('mobile-app-qr.svg')}
              width={150}
              height={150}
              alt="mobile app qr"
            />
          )}
        </Box>
      </TransitionWrapper>

      <Box marginTop="32px">{actions}</Box>
    </FormControl>
  );
};
